module.exports = [{
      plugin: require('/vercel/path0/node_modules/gatsby-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"]},
    }]
